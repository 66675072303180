
<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      errors: null,
      passwordType: 'password',
      countSubmit: 0
    }
  },
  apollo: {
  },
 async mounted() {
    var that = this;
    var url_redirect = await  that.$CoreService.geturlRedirectLogin();
    if (that.$commonFuction.isEmpty(url_redirect)){
      that.$router.push({path: that.$constants.URL_ERR.FOBIDDEN});
    }else {
      that.$router.push({path: url_redirect});
    }
  }
}
</script>